<template>
  <transition appear @before-enter="beforeEnter" @enter="enter">
    <div class="bg-[#FEFAF1] flex justify-center px-[24px] pt-[60px] md:px-20">
      <div class="w-full md:w-[76vw] flex justify-center">
        <div :class="['w-full flex flex-col md:flex-row items-center gap-8', { 'justify-center': !heroImg }]">
          <!-- Content Section -->
          <div :class="['w-full pb-8 md:pb-0', heroImg ? 'md:w-1/2 text-left' : 'text-center']">
            <h1
              class="text-[#1A1A1A] text-[32px] md:text-[60px] font-semibold leading-[110%] helvetica"
              :class="{ 'mx-auto': !heroImg }"
              v-html="heroTitle"
            ></h1>
            <p
              class="mt-4 mb-8 text-[15px] md:text-[20px] leading-[150%] text-[#4A4A4A] font-normal"
              :class="[heroImg ? 'md:max-w-[500px]' : 'max-w-2xl mx-auto']"
              v-html="heroDesc"
            ></p>
            <div :class="['gap-4 flex flex-col md:flex-row', { 'justify-center': !heroImg }]">
              <a
                v-if="heroBtnText"
                :href="heroBtnLink"
                class="px-8 py-4 bg-brand-black inline-block rounded-2xl text-white font-semibold hover:bg-brand-black/90 transition-colors duration-300"
              >
                {{ heroBtnText }}
              </a>
              <a
                v-if="heroBtnTwoText"
                :href="heroBtnTwoLink"
                class="px-8 py-4 bg-white inline-block rounded-2xl text-brand-black font-bold border-2 border-brand-black hover:bg-brand-black hover:text-white transition-all duration-300"
              >
                {{ heroBtnTwoText }}
              </a>
            </div>
          </div>

          <!-- Image Section -->
          <div v-if="heroImg" class="w-full md:w-1/2 relative">
            <img :src="heroImg" :alt="heroImgAlt || 'Hero image'" :class="['w-full h-auto object-cover rounded-2xl', imageClass]" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { onMounted } from 'vue';

const props = defineProps({
  heroImg: { type: String },
  heroImgAlt: { type: String },
  heroTitle: { type: String },
  heroDesc: { type: String },
  heroBtnText: { type: String },
  heroBtnLink: { type: String, default: '/contact' },
  heroBtnTwoText: { type: String },
  heroBtnTwoLink: { type: String, default: '/contact' },
  imageClass: { type: String },
});

const beforeEnter = (el) => {
  el.style.opacity = '0';
  el.style.transform = 'translateY(-100px)';
};

const enter = (el) => {
  gsap.to(el, {
    duration: 2.5,
    y: 0,
    x: 0,
    opacity: 1,
    ease: 'elastic.inOut(1,0.3)',
  });
};
</script>
