<template>
  <article v-if="employer" class="job-card">
    <div class="banner-container">
      <div class="banner-image" :style="{ backgroundImage: `url(${bannerUrl})` }"></div>
    </div>

    <div class="job-content">
      <header class="employer-header">
        <img :src="employer.logo_url" :alt="`${employer.name} logo`" class="employer-logo" width="24" height="24" loading="lazy" decoding="async" />
        <h2 class="employer-name">{{ employer.name }}</h2>
      </header>

      <div class="job-details">
        <h3 class="job-title">{{ job.role_title }}</h3>
        <p class="job-description">{{ stripTags(job.description) }}</p>

        <div class="location-info">
          <img :src="locationIcon" class="location-icon" alt="Location icon" width="16" height="16" />
          <span class="location-text">{{ locationText }}</span>
        </div>

        <div class="job-meta">
          <div class="job-tags">
            <span class="tag">{{ formattedJobType }}</span>
            <span class="tag">{{ formattedWorkType }}</span>
          </div>

          <router-link :to="jobDetailsUrl" class="details-link">More Details</router-link>
        </div>
      </div>

      <footer class="job-footer">
        <div class="salary-info">
          <p class="salary-text" v-if="hasSalaryInfo">
            {{ formattedSalary }} /<span class="period">{{ formattedPayPeriod }}</span>
          </p>
          <p class="salary-text" v-else>
            <span class="negotiable">Salary Negotiable</span>
          </p>
        </div>

        <div class="post-date">
          <img :src="calendarIcon" class="calendar-icon" alt="Calendar icon" width="16" height="16" />
          <time :datetime="job.created_at" class="date-text">{{ daysAgo }} days ago</time>
        </div>
      </footer>
    </div>
  </article>
</template>

<script setup>
import { computed } from 'vue';
import { currencySymbol, formatNumber, getDifferenceInDays, formatTitle, stripTags } from '@/utils/helpers';
import calendarIcon from '@/assets/icons/calendar.svg';
import locationIcon from '@/assets/icons/location.svg';
import bgImage from '@/assets/images/hiring.jpg';

const props = defineProps({
  job: {
    type: Object,
    required: true,
    validator: (value) => {
      return value && value.employer;
    },
  },
});

// Computed properties for better performance
const employer = computed(() => props.job.employer);
const bannerUrl = computed(() => employer.value.banner_url || bgImage);

const formattedJobType = computed(() => props.job.type.split('_').join(' '));
const formattedWorkType = computed(() => props.job.work_type.split('_').join(' '));
const formattedPayPeriod = computed(() => props.job.pay_period.split('_').join(' '));

const jobDetailsUrl = computed(() => `/${formatTitle(employer.value.name)}/${props.job.fid}/${formatTitle(props.job.role_title)}`);

const locationText = computed(() => {
  if (!props.job.state?.name && !props.job.state?.country_code) return 'Anywhere';
  return `${props.job.state.name}, ${props.job.state.country_code}`;
});

const hasSalaryInfo = computed(() => {
  const salary = props.job.salary_min ? parseInt(props.job.salary_min) : 0;
  return salary > 0;
});

const formattedSalary = computed(() => currencySymbol(props.job.salary_currency) + formatNumber(props.job.salary_min));

const daysAgo = computed(() => getDifferenceInDays(props.job.created_at));
</script>

<style scoped>
.job-card {
  @apply p-6 rounded-lg bg-white;
  contain: content;
}

.banner-container {
  contain: strict;
}

.banner-image {
  @apply bg-cover bg-center w-full h-[224px] bg-no-repeat rounded-lg overflow-hidden;
  background-size: 100% 100%;
  transform: translateZ(0);
  will-change: transform;
}

.employer-header {
  @apply flex items-center my-6;
}

.employer-logo {
  @apply mr-2;
  transform: translateZ(0);
}

.employer-name {
  @apply text-brand-black text-[20px] leading-[110%];
}

.job-details {
  @apply border-b border-[#F1F1F1] mb-4;
}

.job-title {
  @apply text-brand-black text-[20px] leading-[28px] font-bold mb-1;
}

.job-description {
  @apply text-[#64666C] leading-[22px] text-[14px] mb-6;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.location-info {
  @apply inline-flex items-center mb-6;
}

.location-icon {
  @apply sm:mr-2;
}

.location-text {
  @apply text-[#64666C] text-[13px];
}

.job-meta {
  @apply block md:flex justify-between flex-wrap mb-2;
}

.job-tags {
  @apply inline-flex mb-4 mr-3 items-start gap-2;
}

.tag {
  @apply bg-[#F1F1F1] text-[12px] inline-block px-4 py-2 rounded-3xl capitalize;
}

.details-link {
  @apply inline-block px-4 py-3 rounded-3xl bg-brand-black text-white mb-4;
  transition: background-color 0.3s ease-out;
}

.details-link:hover {
  @apply bg-opacity-90;
}

.job-footer {
  @apply flex justify-between mb-2;
}

.salary-text {
  @apply font-bold text-[16px];
}

.period,
.negotiable {
  @apply font-normal;
}

.post-date {
  @apply flex items-center;
}

.calendar-icon {
  @apply mr-2;
}

.date-text {
  @apply text-[14px] text-[#64666C] whitespace-nowrap;
}
</style>
