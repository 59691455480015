<template>
  <div class="bg-gradient-to-b from-white to-gray-50">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        <!-- Candidate Card -->
        <div class="group bg-brand-black rounded-3xl p-8 transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl">
          <div class="flex items-center space-x-4 mb-6">
            <div class="w-12 h-12 bg-[#FDE442]/10 rounded-full flex items-center justify-center">
              <svg class="w-6 h-6 text-[#FDE442]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </div>
            <div class="w-20 h-1 bg-[#FDE442] rounded-full"></div>
          </div>

          <h3 class="text-2xl font-bold text-white mb-4">Candidate Signup</h3>
          <p class="text-gray-300 mb-8 text-lg leading-relaxed">Create a profile and find your next opportunity</p>

          <a
            :href="$candidateUrl + '/register'"
            class="inline-flex items-center text-[#FDE442] hover:text-[#FDE442]/90 font-medium group-hover:translate-x-1 transition-transform duration-200"
          >
            Sign up to get hired
            <svg class="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </a>
        </div>

        <!-- Employer Card -->
        <div class="group bg-brand-black rounded-3xl p-8 transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl">
          <div class="flex items-center space-x-4 mb-6">
            <div class="w-12 h-12 bg-[#00F5D4]/10 rounded-full flex items-center justify-center">
              <svg class="w-6 h-6 text-[#00F5D4]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                />
              </svg>
            </div>
            <div class="w-20 h-1 bg-[#00F5D4] rounded-full"></div>
          </div>

          <h3 class="text-2xl font-bold text-white mb-4">Employer Signup</h3>
          <p class="text-gray-300 mb-8 text-lg leading-relaxed">Sign up and find your next team member. You can use the self serve</p>

          <a
            href="https://employer.thecareerbuddy.com/"
            class="inline-flex items-center text-[#00F5D4] hover:text-[#00F5D4]/90 font-medium group-hover:translate-x-1 transition-transform duration-200"
          >
            Sign up hire
            <svg class="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </a>
        </div>

        <!-- Start Hiring Card -->
        <div class="group bg-brand-black rounded-3xl p-8 transform transition-all duration-300 hover:-translate-y-2 hover:shadow-xl">
          <div class="flex items-center space-x-4 mb-6">
            <div class="w-12 h-12 bg-[#FE6D74]/10 rounded-full flex items-center justify-center">
              <svg class="w-6 h-6 text-[#FE6D74]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div class="w-20 h-1 bg-[#FE6D74] rounded-full"></div>
          </div>

          <h3 class="text-2xl font-bold text-white mb-4">Start Hiring</h3>
          <p class="text-gray-300 mb-8 text-lg leading-relaxed">Reach out to our team to instantly connect you to top talents you want to hire.</p>

          <router-link
            to="/contact"
            class="inline-flex items-center text-[#FE6D74] hover:text-[#FE6D74]/90 font-medium group-hover:translate-x-1 transition-transform duration-200"
          >
            See how it works
            <svg class="w-5 h-5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>
